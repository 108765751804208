import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const LOGO_WIDTH = 50;
const LOGO_HEIGHT = 36;

const SkillLogo = ({ name, size = 4, toolTip = false }) => {
  const {
    file,
    allSkillsYaml: { skills },
  } = useStaticQuery(graphql`
    query SkillLogo {
      allSkillsYaml {
        skills: nodes {
          name
          group
          logoPos
        }
      }
      file(relativePath: { eq: "skills-logos.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const skillsMap = new Map(
    skills.reduce((map, skill) => [...map, [skill.name, skill]], [])
  );
  const skill = skillsMap.get(name) || { logoPos: -LOGO_HEIGHT };
  const offset = ((size * 10) / LOGO_WIDTH) * LOGO_HEIGHT;

  return (
    <div className="skill-logo">
      <span
        aria-label={skill.name}
        className="skill-logo-img"
        style={{
          width: `${size * 10}px`,
          backgroundImage: `url(${file.childImageSharp.fixed.src})`,
          backgroundPosition: `0 ${-skill.logoPos * offset}px`,
        }}
      ></span>

      {toolTip && <span className="skill-tooltip">{skill.name}</span>}
    </div>
  );
};

export default SkillLogo;
